import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Wandgemaelde1 from "../components/wandgemaelde1"
import Wandgemaelde2 from "../components/wandgemaelde2"
import Wandgemaelde3 from "../components/wandgemaelde3"

import "./index.css"

export default function WandgemäldePage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Wandgemaelde"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Wandgemaelde"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Wandmalerei</h1>
        <h2 className="referenceH2">
          Deckengemälde, Mariae Himmelfahrt, ehemalige Benediktinerabteikirche,
          Frauenzell
        </h2>
        <h3 className="referenceH3">
          Notkonservierung, Rissverschluss und Retusche,
          Mikroorganismenbehandlung, Bestandsaufnahme
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Wandgemaelde1">
              <Wandgemaelde1 id="Wandgemaelde1" />
              Fremdartige Farbtöne weisen auf Übermalung hin
              <br />
              (Foto: Herbert Stolz)
            </label>
            <label htmlFor="Wandgemaelde2">
              <Wandgemaelde2 id="Wandgemaelde2" />
              Kuppelgemälde nach der Rissschließung im südlichen Bereich.
              Darstellung einer Engelgruppe, von denen einer mit der Schalmei
              musiziert und ein anderer das Notenblatt hält
              <br />
              (Foto: Herbert Stolz)
            </label>
            <label htmlFor="Wandgemaelde3">
              <Wandgemaelde3 id="Wandgemaelde3" />
              Kuppelgemälde, nach der Retusche des Risses im südlichen Bereich
              <br />
              (Foto: Herbert Stolz)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Nach der Grundsteinlegung 1737 erfolgte erst 1745 die Errichtung
              des Bauwerks. Die Konzeption geht vermutlich auf die Gebrüder
              Cosmos und Damian Asam zurück. Die Stuckaturen schuf Anton Landes.
              Die Deckengemälde sind ein Werk der Asamschülern Andreas und Otto
              Gebhard um 1750. Die hohe Kunstfertigkeit der Gebrüder Gebhard ist
              an den Gemälden insbesondere an der Mimik und Gestik sowie an den
              illusionistischen Details zu erkennen, wenngleich die Malerei von
              zahlreichen Übermalungen aus der vorangegangenen Restaurierung
              geprägt ist.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Im Zuge der statischen Ertüchtigung (Mauerwerksringanker) des
              Gewölbes wurden Maßnahmen, wie eine Rissverpressung, die das
              staatliche Bauamt beauftragte, notwendig. Die Rissöffnungen im
              Gewölbe hatten eine Breite zwischen 2 und 9 cm. Auf der
              Maleroberfläche haben sich Mikroorganismen gebildet. Hochstehende
              Malschichten waren vereinzelt zu verzeichnen.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Die geöffneten Risse in den vier Eckpunkten sollten geschlossen
              und retuschiert werden. Eine Notsicherung war vorzunehmen.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Die vorgefundenen Risse wurden geschlossen und retuschiert. Es
              folgte darüber hinaus eine Konservierung der aufstehenden
              Malschichten und eine Behandlung der aufliegenden Mikroorganismen
              mit Isopropanol, um diese einzudämmen. Es wurde eine
              Fotodokumentation des Ist-Zustandes mit einer Bestandskartierung
              und einer Schadensbeschreibung angefertigt.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">Staatliches Bauamt Regensburg.</p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Landesamt für Denkmalpflege Gebietsreferent Dr. Harald Gieß.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
